import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TimeAgo from "react-timeago";

export const TimeAgoIntl: FC<TimeAgo.ReactTimeagoProps> = (props) => {
  const intl = useIntl();
  const [timeAgoStrings, setTimeAgoStrings] = useState();
  useEffect(() => {
    (async () => {
      const resp = await import("react-timeago/lib/language-strings/" + intl.locale + "-short");
      setTimeAgoStrings(resp.default);
    })();
  }, [intl.locale]);

  const timeFormatter = (value: any, unit: TimeAgo.Unit) => {
    let res = "";
    const isPlural = value > 1;
    if (isPlural) {
      res = timeAgoStrings?.[unit + "s"] || timeAgoStrings?.[unit] || "%d " + unit;
    } else {
      res = timeAgoStrings?.[unit] || timeAgoStrings?.[unit + "s"] || "%d " + unit;
    }
    return res.replace(/%d/g, value);
  };

  return <TimeAgo formatter={timeFormatter} {...props} />;
};
