import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { ProgressBar, SimpleCard } from "../../components/widget";
import { useAuth } from "../../hooks/use-auth";
import { useUsers } from "../../hooks/use-users";
import { incrementStatusCounter } from "../../utils/utils";
export const UserDashboard = () => {
  const { user } = useAuth();
  const { fetchDashboard, dashboard, fetchUserById } = useUsers();

  useEffect(() => {
    fetchDashboard().then();
    fetchUserById("me").then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const completionRate = (() => {
    let countStatus: any = {
      SIGNED: 0,
      IN_PROGRESS: 0,
      CANCEL: 0,
    };
    countStatus = incrementStatusCounter(countStatus, dashboard?.counterSignatureRequest);
    const totalSignature = countStatus.SIGNED + countStatus.IN_PROGRESS + countStatus.CANCEL;
    return totalSignature === 0 ? 0 : Math.round((countStatus.SIGNED / totalSignature) * 100);
  })();
  return (
    <div className="row gy-5 g-xl-8">
      <div className="col-xxl-6">
        <div className="card card-custom bg-white mb-4 mb-xl-8">
          <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
            <div className="mr-2">
              <h3 className="font-weight-bolder">
                <FormattedMessage id="GLOBAL.HELLO" values={{ name: user?.name ? `, ${user?.name}` : "" }} />
              </h3>
              <i>
                <div>{new Date().toLocaleDateString()}</div>
              </i>
            </div>
          </div>
        </div>
        <div className="row gy-5 g-xl-8">
          <div className="col-md-8">
            <ProgressBar
              className="mb-4 mb-xl-8"
              title="GLOBAL.COMPLETION_RATE"
              description=""
              color={completionRate > 66 ? "success" : completionRate > 33 ? "warning" : "danger"}
              progress={`${completionRate}%`}
            />
            <div className="card card-custom mb-4 mb-xl-8">
              <SimpleCard
                chartColor="primary"
                icon="/media/icons/duotune/general/gen008.svg"
                info={{
                  data: `${dashboard?.currentMonthCosts ?? 0}€`,
                  title: "GLOBAL.CURRENT_MONTH",
                }}
              />
            </div>
            <div className="card card-custom">
              <SimpleCard
                chartColor="primary"
                icon="/media/icons/duotune/general/gen009.svg"
                info={{
                  data: `${dashboard?.lastMonthCosts ?? 0}€`,
                  title: "GLOBAL.LAST_MONTH",
                }}
              />
            </div>
          </div>
          <div className="d-none d-md-flex col-md-4">
            <div className="card bg-transparent overflow-hidden">
              <img alt="dashboard" src="/media/dashboard/cigdl.jpg" className="h-100" style={{ objectFit: "cover" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6">
        <div className="card card-custom mb-4 mb-xl-8">
          <SimpleCard
            chartColor="success"
            icon="/media/icons/duotune/general/gen048.svg"
            info={{
              data: `${dashboard?.counterSignatureRequest?.SIGNED?.SIMPLE ?? 0}`,
              title: "GLOBAL.SIMPLE_SIGNATURE",
            }}
          />
        </div>
        <div className="card card-custom">
          <SimpleCard
            chartColor="success"
            icon="/media/icons/duotune/general/gen051.svg"
            info={{
              data: `${dashboard?.counterSignatureRequest?.SIGNED?.ADVANCED ?? 0}`,
              title: "GLOBAL.ADVANCED_SIGNATURE",
            }}
          />
        </div>
      </div>
    </div>
  );
};
