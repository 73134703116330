import React, { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import { KTSVG } from "../../../_metronic/helpers";
import { ESignatoryStatus, ESignatureStatus } from "../../../types/signatory";
import { useSign } from "../../hooks/use-sign";
import { Utils } from "../../utils/utils";

import { PdfViewer } from "./components/PdfViewer";
import { SignLayout } from "./components/SignLayout";
import { OtpModal } from "./OtpModal";
import { SignatureInformationModal } from "./SignatureInformationModal";

const SignPage: FC = () => {
  const { selectedSignature, fetchSignatureById, loadings, setSignStepState, signStepState } = useSign();
  const { signatureId } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [location, setLocation] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    fetchSignatureById(signatureId, searchParams.get("key") || "").then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureId, searchParams]);

  const getContentToDisplay = (selectedSignature: any, signStepState: any) => {
    if ((selectedSignature?.signatory?.signatorySigningStatus === ESignatureStatus.SIGNED && !signStepState) || !selectedSignature) {
      return (
        <h2>
          <FormattedMessage id="SIGN.NOTHING_TO_SIGN" />
        </h2>
      );
    }

    if (signStepState !== ESignatureStatus.SIGNED && selectedSignature) {
      return (
        <div className="w-100 h-100 position-absolute">
          <PdfViewer fileUrl={selectedSignature.documentUrl} />
        </div>
      );
    }

    if (signStepState === ESignatureStatus.SIGNED && selectedSignature?.signatory?.signatorySigningStatus === ESignatoryStatus.SIGNED) {
      return (
        <div className="d-flex flex-column align-items-center">
          <KTSVG className="svg-icon-success mb-4" svgClassName="h-100px w-100px" path="/media/icons/duotune/files/fil025.svg" />
          <h2 className="mb-2">
            <FormattedMessage id="SIGN.DOCUMENT_SIGN_SUCCESS" />
          </h2>
          <div className="mb-8">
            {selectedSignature.status === ESignatureStatus.SIGNED ? (
              //all signatories signed => document signed completely, enable download
              <FormattedMessage
                id="SIGNATURE.EMAIL_SENT"
                values={{
                  b: (chunks: any) => <b>{chunks}</b>,
                  email: selectedSignature.signatoryEmail,
                }}
              />
            ) : (
              <FormattedMessage id="GLOBAL.FINAL_DOCUMENT_SEND_EXPLANATION" />
            )}
          </div>
          {selectedSignature.status === ESignatureStatus.SIGNED && selectedSignature.fileUrl && (
            <button className="btn btn-lg btn-success" onClick={() => Utils.downloadFile(selectedSignature.fileUrl, selectedSignature.fileName)}>
              <FormattedMessage id="GLOBAL.DOWNLOAD" />
            </button>
          )}
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <SignatureInformationModal location={location} setLocation={setLocation} phoneNumber={phoneNumber || selectedSignature?.signatory?.signatoryMobile} setPhoneNumber={setPhoneNumber} />
      <OtpModal location={location} phoneNumber={phoneNumber} />
      <SignLayout
        signatureTitle={selectedSignature?.signatureTitle ?? ""}
        footer={
          selectedSignature?.signatory?.signatorySigningStatus !== ESignatoryStatus.SIGNED && (
            <div className="d-flex justify-content-center">
              {selectedSignature && (
                <button className="btn btn-md px-10 btn-primary" onClick={() => setSignStepState("INFO")}>
                  <KTSVG path="/media/icons/duotune/art/art005.svg" />
                  <FormattedMessage id="SIGN.ACTION" />
                </button>
              )}
            </div>
          )
        }
      >
        {loadings.has("fetchSignature") ? (
          <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="spinner-border spinner-primary spinner-border-lg" />
          </div>
        ) : (
          getContentToDisplay(selectedSignature, signStepState)
        )}
      </SignLayout>
    </>
  );
};

export { SignPage };
