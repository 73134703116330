import clsx from "clsx";
import Jimp from "jimp";
import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";

import { toAbsoluteUrl } from "../../_metronic/helpers";
import { User } from "../../types/user";
import { useUsers } from "../hooks/use-users";
import { getSeal } from "../modules/signatures/components/imageUtils";

type Props = { selectedUser: User };
export const FileUploadWidget: FC<Props> = ({ selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone();
  const { setUserAvatar, updateUser } = useUsers();

  const [sealPreview, setSealPreview] = useState<any>(undefined);

  const [fileUpload, setFileUpload] = useState<File | undefined>(undefined);

  const upload = async (e: any) => {
    const file = e.dataTransfer?.files ? e.dataTransfer.files[0] : e.target?.files ? e.target.files[0] : null;

    if (file) {
      setFileUpload(file);
    }
  };

  useEffect(() => {
    if (selectedUser?.logoUrl) {
      getSeal(selectedUser?.logoUrl + "?" + new Date().getTime())
        .then((res) => setSealPreview(res))
        .catch((e) => console.warn("unable to load avatar"));
    }
  }, [selectedUser]);

  useEffect(() => {
    const getLogoCropped = async (file: any) => {
      const logo = await Jimp.read(file);

      const logoWidth = logo.bitmap.width;
      const logoHeight = logo.bitmap.height;

      const aspectRatio = logoWidth / logoHeight;

      // let paddingLeft = 260;
      const paddingTop = 10; //default 10 pixel;

      // const sealTemplate = await Jimp.read(toAbsoluteUrl("/media/templates/seal_template.png"));

      let widthT = 0;
      let heightT = 0;
      if (logoWidth > logoHeight) {
        widthT = 210;
        heightT = widthT / aspectRatio;

        // paddingTop = (sealTemplate.bitmap.height - heightT) / 2;
      } else {
        heightT = 200 - paddingTop;
        widthT = 200 * aspectRatio;
        // paddingLeft = 260 + (sealTemplate.bitmap.width - 260 - widthT) / 2;
      }

      await logo.resize(widthT, heightT);

      return await logo.getBufferAsync("image/png");
    };

    const uploadAvatar = async (file: any) => {
      const { urlLogo, urlSealTemplate, urlThumbnail } = await setUserAvatar({
        user: selectedUser,
        file: file,
      });
      setSealPreview(urlSealTemplate);
      await updateUser({ ...selectedUser, logoUrl: urlLogo, thumbnailUrl: urlThumbnail });
    };
    const getImageBase64 = async (fileUpload: File) => {
      return await toBase64(fileUpload);
    };
    if (fileUpload) {
      setLoading(true);
      getImageBase64(fileUpload)
        .then((res) =>
          getLogoCropped(res)
            .then((croppedImage) => {
              uploadAvatar(croppedImage).then(() => {
                setLoading(false);
              });
            })
            .catch((e) => {
              console.error(e);
              setLoading(false);
            }),
        )
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload]);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div className="image-input image-input-outline mb-6 w-100">
      <div {...getRootProps({ onDrop: (e) => upload(e) })} className="image-input-wrapper align-self-center mw-100 h-auto w-auto overflow-hidden">
        <OverlayTrigger
          overlay={
            <Tooltip id="change-photos">
              <FormattedMessage id="USERS.AVATAR_UPLOAD_ACTION_TOOLTIP" />
            </Tooltip>
          }
        >
          <div>
            <div className=" btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute  mt-n7 end-0 " data-action="change" data-toggle="tooltip">
              <i className="fa fa-upload icon-sm text-muted" />
            </div>
          </div>
        </OverlayTrigger>

        <div className="upload-file-container cursor-pointer min-h-200px">
          <input name="file" {...getInputProps({ onChange: (e) => upload(e) })} accept=".png, .jpg, .jpeg" disabled={loading} />

          <div
            className={clsx("m-2 d-flex align-items-center min-h-200px cursor-pointer justify-content-center")}
            style={{
              backgroundColor: "#F0F1F9",
            }}
          >
            {!loading ? (
              <>
                {selectedUser?.logoUrl && sealPreview ? (
                  <div className="d-flex align-items-center mx-auto">
                    <div className="m-3">
                      <img className="border border-secondary shadow-sm mw-100 mh-200px overflow-hidden" src={selectedUser?.logoUrl + "?" + new Date().getTime()} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column" style={{ opacity: "0.4" }}>
                    <span className="svg-icon svg-icon-5x">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Camera.svg")} />
                    </span>
                    <div className="text-center">
                      <FormattedMessage id="USERS.AVATAR_UPLOAD_DESCRIPTION" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center" style={{ opacity: "0.4" }}>
                <div className="spinner-border spinner-primary spinner-border-lg" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
