import axios from "axios";
import { ArrayHelpers } from "formik";

import { ISignatory } from "../../types/signatory";

export class Utils {
  static downloadFile = (fileUrl: string, fileName: string) => {
    axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  };
}
export const incrementStatusCounter = (totalCount: any, counterSignatureRequest: any) => {
  Object.keys(totalCount).forEach(
    (keyCountStatus) =>
      (totalCount[keyCountStatus] += Object.keys(counterSignatureRequest?.[keyCountStatus] ?? {}).reduce((total, key) => total + (counterSignatureRequest[keyCountStatus][key] ?? 0), 0)),
  );
  return totalCount;
};

export function moveItemUp(e: any, array: ISignatory[], item: ISignatory, arrayHelpers: ArrayHelpers) {
  e.preventDefault();
  const currentIndex = array.findIndex((cItem) => cItem.signatoryId === item.signatoryId);
  if (currentIndex <= 0) {
    return;
  }
  arrayHelpers.swap(currentIndex, currentIndex - 1);
}

export function moveItemDown(e: any, array: ISignatory[], item: ISignatory, arrayHelpers: ArrayHelpers) {
  e.preventDefault();
  const currentIndex = array.findIndex((cItem) => cItem.signatoryId === item.signatoryId);
  if (currentIndex >= array.length - 1) {
    return;
  }
  arrayHelpers.swap(currentIndex, currentIndex + 1);
}

export function moveItem(array: Array<any>, from: number, to: number) {
  // remove `from` item and store it
  const f = array.splice(from, 1)[0];
  // insert stored item into position `to`
  array.splice(to, 0, f);
  return array;
}

export function calculateTextHeight(text: string, fontFamily: string, fontSize: string) {
  // Create a temporary element to measure text height
  const tempElement = document.createElement("div");
  tempElement.style.visibility = "hidden";
  tempElement.style.position = "absolute";
  tempElement.style.top = "-1000px"; // Move it off-screen

  // Set font properties
  tempElement.style.fontFamily = fontFamily;
  tempElement.style.fontSize = fontSize;

  // Set the text content
  tempElement.textContent = text;

  // Append the element to the document
  document.body.appendChild(tempElement);

  // Measure the height
  const textHeight = tempElement.clientHeight;

  // Remove the temporary element from the document
  document.body.removeChild(tempElement);

  return textHeight;
}
