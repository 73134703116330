/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { API } from "aws-amplify";

interface FooterProps {
  mode?: string;
}

const Footer: FC<FooterProps> = ({ mode }) => {
  const { classes } = useLayout();
  const intl = useIntl();

  const openInNewTab = async () => {
    //note: for luxembourgish user guide (ug-lu.pdf), german version is used (on purpose)
    //example url path: /files/ug-en.pdf?lang=en
    //lang=en is used in case where a file is stored in a language subfolder inside of the bucket:
    // e.g. /files/app/en/ug-en.pdf
    //      /files/app/de/ug-de.pdf ...
    const guideUrl = await API.get("API", `/files/ug-${intl.locale}.pdf?lang=${intl.locale}`, {});
    if (guideUrl?.fileUrl) {
      window.open(guideUrl?.fileUrl, "_blank", "noopener,noreferrer");
    } else {
      console.warn("no url provided");
    }
  };

  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div className={clsx(classes.footerContainer, "d-flex flex-column flex-md-row align-items-center", mode === "login" ? "justify-content-center" : "justify-content-between")}>
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold">{new Date().getFullYear()} &copy;</span>
          <span className="text-muted fw-bold ms-2">
            lux-sign <FormattedMessage id={"GLOBAL.POWERED_BY"} />{" "}
            <a target="_blank" rel="noreferrer" href="https://www.chambre-immobiliere.lu/" className="text-hover-primary">
              Chambre Immobilière
            </a>
            <span> x </span>
            <a target="_blank" rel="noreferrer" href="https://www.luxtrust.com/" className="text-hover-primary">
              LuxTrust
            </a>
            <span> x </span>
            <a target="_blank" rel="noreferrer" href="https://www.kodehyve.com/" className="text-hover-primary">
              kodehyve
            </a>
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {mode !== "login" && (
          <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
              <button
                // @ts-ignore
                onClick={window.showCollectorDialog}
                id="feedback-button"
                className="btn fs-7 menu-link ps-0 pe-5"
              >
                <FormattedMessage id={"GLOBAL.REPORT_BUG"} />
              </button>
            </li>

            <li className="menu-item">
              <button id="feedback-button" className="btn fs-7 menu-link ps-0 pe-2" onClick={async () => openInNewTab()}>
                <FormattedMessage id={"GLOBAL.USER_GUIDE"} />
              </button>
            </li>
          </ul>
        )}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
