import cn from "clsx";
import { updatedDiff } from "deep-object-diff";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { intersection } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { CardLoaderSpinner } from "../../../../../components/CardLoaderSpinner";
import { FileUploadWidget } from "../../../../../components/FileUploadWidget";
// @ts-expect-error TODO migrate to ts
import { PhoneFormInput } from "../../../../../components/PhoneFormInput";
import { UserLoading } from "../../../../../contexts/users-context";
import { useAuth } from "../../../../../hooks/use-auth";
import { useLoadings } from "../../../../../hooks/use-loadings";
import { useUsers } from "../../../../../hooks/use-users";
import { isAdmin } from "../../../../../utils/authUtils";
import { getTranslation } from "../../../../../utils/utilsValidation";

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  language: "",
  logoUrl: "",
  additionalInfo: {
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      stateOrRegion: "",
      postalCode: "",
    },
    email: "",
    company: {
      name: "",
      vatNumber: "",
      registrationNumber: "",
      nationalRegistrationNumber: "",
    },
  },
  gdprApprovedAt: "",
};

export const ProfileDetails: React.FC = () => {
  const intl = useIntl();
  const { user } = useAuth();

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required().label("GLOBAL.NAME"),
    email: Yup.string().email(getTranslation(intl, "GLOBAL.INVALID_EMAIL")).required().label("GLOBAL.EMAIL"),
    mobile: Yup.string().required().label("GLOBAL.MOBILE"),
    language: Yup.string().required().label("GLOBAL.LANGUAGE"),
  });

  const { selectedUser, createUser, updateUser, invalidEmail } = useUsers();
  const { loadings } = useLoadings();

  const formRef = useRef<FormikProps<any>>(null);

  const isUserNotAdmin: boolean = useMemo(() => isAdmin(user) === undefined, [user]);

  useEffect(() => {
    if (invalidEmail && formRef.current) {
      formRef.current.setErrors({
        ...formRef.current.errors,
        email: intl.formatMessage({ id: "VALIDATION.EMAIL_EXIST" }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidEmail]);

  const { userId } = useParams();

  const submitProfile = (values: any) => {
    if (selectedUser?.id) {
      updateUser({ ...selectedUser, ...values }).then();
    } else {
      createUser(values).then();
    }
  };

  const isValueChanged = (values: any) => {
    if (!selectedUser?.id) {
      return;
    }

    return Object.keys(updatedDiff(selectedUser, values)).length !== 0;
  };

  return intersection(loadings, [UserLoading.fetchUserById]).length ? (
    <CardLoaderSpinner />
  ) : (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            <FormattedMessage id={userId === "me" ? "GLOBAL.ACCOUNT_SETTING" : selectedUser ? "USERS.PROFILE_DETAILS" : "USERS.NEW_MEMBER"} />
          </h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <Formik
          enableReinitialize={true}
          initialValues={
            selectedUser
              ? {
                  ...initialValues,
                  ...selectedUser,
                }
              : initialValues
          }
          validationSchema={profileDetailsSchema}
          onSubmit={submitProfile}
          innerRef={formRef}
        >
          {({ values }) => (
            <Form noValidate>
              <div className="card-body border-top p-9">
                {selectedUser?.id && (
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <FormattedMessage id="GLOBAL.LOGO" />
                    </label>
                    <div className="col-lg-8">
                      <FileUploadWidget selectedUser={selectedUser} />
                    </div>
                  </div>
                )}

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <FormattedMessage id="GLOBAL.NAME" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field name="name" className="form-control form-control-lg form-control-solid" placeholder="kodehyve" />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <FormattedMessage id="GLOBAL.EMAIL" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field
                      name="email"
                      type="email"
                      className={cn("form-control", "form-control-lg", "form-control-solid", { "text-muted": isUserNotAdmin })}
                      placeholder="example@gmail.com"
                      disabled={isUserNotAdmin}
                    />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <span>
                      <FormattedMessage id="GLOBAL.MOBILE" />
                    </span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field name="mobile" component={PhoneFormInput} classes="form-control form-control-lg form-control-solid" />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="mobile" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <span>
                      <FormattedMessage id="GLOBAL.LANGUAGE" />
                    </span>
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Field as="select" name="language" className="form-select form-select-solid form-select-lg">
                      <option value="">{intl.formatMessage({ id: "GLOBAL.SELECT_LANGUAGE" })}</option>
                      <option value="en">English</option>
                      <option value="fr">Français</option>
                      <option value="de">Deutsch</option>
                      <option value="lu">Lëtzebuergesch</option>
                    </Field>
                    <div className="text-danger mt-2">
                      <ErrorMessage name="language" />
                    </div>
                  </div>
                </div>

                <div className="row mt-15 mb-6">
                  <h3 className="fs-3 mb-2 text-dark fw-bolder">
                    <FormattedMessage id="USERS.ADDITIONAL_INFORMATION" />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="layout-tooltip" className="tooltip-auto-w">
                          <FormattedMessage id="USERS.ADDITIONAL_INFORMATION_DETAIL" />
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-md svg-icon-info ms-3">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                      </span>
                    </OverlayTrigger>
                  </h3>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <FormattedMessage id="USERS.COMPANY_NAME" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field name="additionalInfo.company.name" className="form-control form-control-lg form-control-solid" placeholder="kodehyve" />
                  </div>
                </div>
                {!!selectedUser?.id && (
                  <>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.VAT_NUMBER" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.company.vatNumber" className="form-control form-control-lg form-control-solid" placeholder="LU 000 000 00" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.REGISTRATION_NUMBER" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.company.registrationNumber" className="form-control form-control-lg form-control-solid" placeholder="B 000 000" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.NATION_NUMBER" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.company.nationalRegistrationNumber" className="form-control form-control-lg form-control-solid" placeholder="000 000 00/0" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.ADDRESS_LINE_1" />
                      </label>
                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.addressLine1" className="form-control form-control-lg form-control-solid" placeholder="12, rue de Bouillon" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.ADDRESS_LINE_2" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.addressLine2" className="form-control form-control-lg form-control-solid" placeholder="12, rue de Bouillon" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.CITY" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.city" className="form-control form-control-lg form-control-solid" placeholder="Luxembourg ville" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.COUNTRY" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.country" className="form-control form-control-lg form-control-solid" placeholder="Luxembourg" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.MUNICIPALITY_REGION" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.stateOrRegion" className="form-control form-control-lg form-control-solid" placeholder="Luxembourg" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.POSTAL_CODE" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.address.postalCode" className="form-control form-control-lg form-control-solid" placeholder="L-2155" />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        <FormattedMessage id="USERS.BILLING_EMAIL" />
                      </label>

                      <div className="col-lg-8 fv-row">
                        <Field name="additionalInfo.email" className="form-control form-control-lg form-control-solid" placeholder="example@gmail.com" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={(selectedUser?.id && !isValueChanged(values)) || !!intersection(loadings, [UserLoading.createUser, UserLoading.updateUser]).length}
                >
                  {!loadings.includes(UserLoading.updateUser) && selectedUser && <FormattedMessage id="GLOBAL.SAVE" />}
                  {!loadings.includes(UserLoading.createUser) && !selectedUser && <FormattedMessage id="GLOBAL.CREATE" />}
                  {!!intersection(loadings, [UserLoading.createUser, UserLoading.updateUser, UserLoading.setUserAvatar]).length && (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      <FormattedMessage id="GLOBAL.PLEASE_WAIT" /> <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
