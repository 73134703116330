import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAuth } from "../../../hooks/use-auth";
import { useUsers } from "../../../hooks/use-users";
import { PASSWORD } from "../../../utils/regExUtils";

const initialValues = {
  password: "",
  passwordRepeat: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function NewPasswordRequired() {
  const intl = useIntl();

  const auth = useAuth();
  const { selectedUser } = useUsers();

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8)
      .max(50)
      .required()
      .matches(PASSWORD, intl.formatMessage({ id: "USERS.PASSWORD_REGEX" }))
      .label("GLOBAL.PASSWORD"),
    passwordRepeat: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], intl.formatMessage({ id: "GLOBAL.MATCH_PASSWORD" }))
      .label("AUTH.INPUT.CONFIRM_PASSWORD"),
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const submitNewPassword = (values: any, { setStatus, setSubmitting }: FormikValues) => {
    if (values.password === values.passwordRepeat) {
      setLoading(true);
      setStatus();
      auth
        .completeNewPassword(auth.user?.cognitoUser, values.password)
        .then(() => {
          setLoading(false);
          if (selectedUser?.gdprApprovedAt) {
            navigate("/dashboard");
          } else {
            navigate("/init/gdpr");
          }
        })
        .catch((err) => {
          console.error("Set a new password - Error:", err);
          setLoading(false);
          setSubmitting(false);
          setStatus(err.code);
        });
    } else {
      setStatus("PasswordMustMatch");
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={submitNewPassword}>
      {({ status, isSubmitting, isValid }) => (
        <Form className="form w-100" noValidate id="kt_login_signin_form">
          {/* begin::Heading */}
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">
              <FormattedMessage id="AUTH.NEW_PASSWORD_REQUIRED" />
            </h1>
          </div>
          {/* begin::Heading */}

          {status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{status}</div>
            </div>
          )}

          {/* begin::Form group */}
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">
              <FormattedMessage id="GLOBAL.PASSWORD" />
            </label>
            <Field name="password" className={clsx("form-control form-control-lg form-control-solid")} type="password" autoComplete="off" />
            <div className="text-danger mt-2">
              <ErrorMessage name="password" />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="fv-row mb-10">
            <div className="d-flex justify-content-between mt-n5">
              <div className="d-flex flex-stack mb-2">
                {/* begin::Label */}
                <label className="form-label fw-bolder text-dark fs-6 mb-0">
                  <FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
                </label>
                {/* end::Label */}
              </div>
            </div>
            <Field type="password" autoComplete="off" name="passwordRepeat" className={clsx("form-control form-control-lg form-control-solid")} />{" "}
            <div className="text-danger mt-2">
              <ErrorMessage name="passwordRepeat" />
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className="text-center">
            <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={isSubmitting || !isValid}>
              {!loading && (
                <span className="indicator-label">
                  <FormattedMessage id="GLOBAL.CONTINUE" />
                </span>
              )}
              {loading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  <FormattedMessage id="GLOBAL.PLEASE_WAIT" /> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </Form>
      )}
    </Formik>
  );
}
