/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/hooks/use-auth";
import { isAdmin } from "../../../../app/utils/authUtils";

export function AsideMenuMain() {
  const intl = useIntl();
  const { user } = useAuth();

  return (
    <>
      <AsideMenuItem to="/dashboard" icon="/media/icons/duotune/art/art002.svg" title={intl.formatMessage({ id: "GLOBAL.DASHBOARD" })} fontIcon="bi-app-indicator" />
      {isAdmin(user) ? (
        <AsideMenuItem to="/users" icon="/media/icons/duotune/communication/com006.svg" title={intl.formatMessage({ id: "GLOBAL.USERS" })} fontIcon="bi-person-circle" />
      ) : (
        <AsideMenuItem to="/signatures" icon="/media/icons/duotune/art/art005.svg" title={intl.formatMessage({ id: "GLOBAL.SIGNATURES" })} fontIcon="bi-pen" />
      )}
    </>
  );
}
