import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export const Checkbox: FC<{
  translationId: string;
  val: boolean;
  setVal: any;
  name?: string;
  params?: any;
}> = ({ val, setVal, translationId, name, params }) => {
  return (
    <div className="row mb-4">
      <div className="col-lg-12 fv-row">
        <div className="d-flex align-items-center mt-3">
          <label className="form-check form-check-inline form-check-solid">
            <input className="form-check-input" name={name} type="checkbox" checked={val} onChange={() => setVal(!val)} />
            <span className="fw">
              <FormattedMessage
                id={translationId}
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                  u: (...chunks: any) => <u>{chunks}</u>,
                  ...params,
                }}
              />
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};
