import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-number-input/input";

import { Checkbox } from "../../components/Checkbox";
import { useSign } from "../../hooks/use-sign";

export function SignatureInformationModal({ location, setLocation, phoneNumber, setPhoneNumber }: any) {
  const { sendCode, signStepState, setSignStepState, selectedSignature } = useSign();

  const [acceptTermsAndService, setAcceptTermsAndService] = useState(false);
  const [acceptConfirmMobile, setAcceptConfirmMobile] = useState(false);
  const [acceptDocumentConsent, setAcceptDocumentConsent] = useState(false);
  const [acceptLegalParagraph, setAcceptLegalParagraph] = useState(false);

  const validateSignatureInfoModal = () => {
    setSignStepState("OTP");
    sendCode().then();
  };

  function capitalizeFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  return (
    <Modal show={signStepState === "INFO"} onHide={() => setSignStepState(undefined)} aria-labelledby="signature-information-modal" centered backdrop="static" size="lg">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="SIGN.VALIDATE_IDENTITY_TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group" style={{ fontSize: 14 }}>
          <div className="mb-1">
            <FormattedMessage id="SIGN.SIGNATORY" />
          </div>
          <label htmlFor="signatoryName" style={{ fontSize: 16 }}>
            <b>{selectedSignature?.signatory?.signatoryFullName}</b>
          </label>
        </div>
        <div className="form-group mt-4" style={{ fontSize: 14 }}>
          <label className="mb-1" htmlFor="location">
            <FormattedMessage id="SIGN.LOCATION" />
          </label>
          <input
            type="text"
            id="location"
            className="form-control form-control-lg form-control-solid"
            placeholder="Bettembourg"
            value={location}
            onChange={(e) => setLocation(capitalizeFirstLetter(e.target.value))}
          />
        </div>
        <div className="form-group mt-4" style={{ fontSize: 14 }}>
          <label className="mb-1" htmlFor="mobile">
            <FormattedMessage id="GLOBAL.MOBILE" />
          </label>
          <PhoneInput disabled={true} id="mobile" className="form-control form-control-lg form-control-solid" value={phoneNumber} onChange={(value: any) => setPhoneNumber(value)} />
        </div>

        <div className="form-group mt-5">
          <Checkbox
            translationId="SIGN.ACCEPT_TERMS_AND_SERVICE"
            val={acceptTermsAndService}
            setVal={setAcceptTermsAndService}
            params={{
              termsLink: (chunks: any) => (
                <u>
                  <a href="https://www.chambre-immobiliere.lu/mentions-legales-et-declaration-de-confidentialite/" target="_blank" rel="noreferrer">
                    {chunks}
                  </a>
                </u>
              ),
              privacyPolicyLink: (chunks: any) => (
                <u>
                  <a href="https://www.chambre-immobiliere.lu/mentions-legales-et-declaration-de-confidentialite/" target="_blank" rel="noreferrer">
                    {chunks}
                  </a>
                </u>
              ),
            }}
          />
          <Checkbox
            translationId="SIGN.ACCEPT_CONFIRM_PHONE_NUMBER"
            val={acceptConfirmMobile}
            setVal={setAcceptConfirmMobile}
            params={{
              mobile: phoneNumber,
            }}
          />
          <Checkbox translationId="SIGN.ACCEPT_DOCUMENT_CONSENT" val={acceptDocumentConsent} setVal={setAcceptDocumentConsent} />
          <Checkbox translationId="SIGN.ACCEPT_LEGAL_PARAGRAPH" val={acceptLegalParagraph} setVal={setAcceptLegalParagraph} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          <button
            disabled={phoneNumber?.length < 10 || location?.length < 3 || !acceptLegalParagraph || !acceptConfirmMobile || !acceptDocumentConsent || !acceptTermsAndService}
            data-cy="button-signature-send-otp"
            type="button"
            className="btn btn-primary"
            onClick={validateSignatureInfoModal}
          >
            <FormattedMessage id="SIGN.SEND_CODE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
