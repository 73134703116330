import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { KTSVG } from "../../../_metronic/helpers";
import { TimeAgoIntl } from "../../components/TimeAgoIntl";
import { useSign } from "../../hooks/use-sign";

export function OtpModal({ location, phoneNumber }: any) {
  const { signStepState, setSignStepState, sendCode, validateAndSign, loadings, nextCodeDelay, signError, setSignError } = useSign();

  const [code, setCode] = useState("");

  const [showDelay, setShowDelay] = useState<boolean>();
  useEffect(() => {
    const interval = setInterval(() => {
      const shouldShowDelay = nextCodeDelay && nextCodeDelay > new Date();
      setShowDelay(shouldShowDelay);
      if (!shouldShowDelay) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [nextCodeDelay]);

  const buttonResendCodeClick = () => {
    sendCode().then();
  };

  return (
    <Modal show={signStepState === "OTP"} onHide={() => setSignStepState(undefined)} aria-labelledby="signature-information-modal" centered size="lg" backdrop="static">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="SIGN.ENTER_CODE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          <FormattedMessage id="SIGN.CODE" />
        </label>
        <input
          data-cy="input-signature-otp"
          type="text"
          className="form-control"
          value={code}
          maxLength={8}
          onChange={(e) => {
            setSignError(undefined);
            setCode(e.target.value.toUpperCase());
          }}
        />
        {signError === "invalidCode" && (
          <div className="text-danger mt-2">
            <FormattedMessage id="SIGN.INVALID_CODE" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="text-right">
          {nextCodeDelay && showDelay && (
            <OverlayTrigger
              key="otp-sign-delay"
              placement="top"
              overlay={
                <Tooltip id="tooltip-otp-sign-delay">
                  <span className="fs-7">
                    <FormattedMessage
                      id="SIGN.WAIT_BEFORE_SEND_CODE"
                      values={{
                        time: (chunk: any) => <TimeAgoIntl date={nextCodeDelay} />,
                      }}
                    />
                  </span>
                </Tooltip>
              }
            >
              <span className="svg-icon btn-icon svg-icon-2x me-2 svg-icon-info">
                <KTSVG path="/media/icons/duotune/general/gen045.svg" />
              </span>
            </OverlayTrigger>
          )}
          <button
            type="button"
            className="btn btn-outline btn-outline-primary btn-active-light-primary me-4"
            onClick={() => buttonResendCodeClick()}
            disabled={(nextCodeDelay && nextCodeDelay > new Date()) || loadings.has("sendCode") || loadings.has("validateAndSign")}
          >
            <FormattedMessage id="SIGN.RESEND_CODE" />
          </button>
          <button
            //8 = current otp length
            disabled={!code || (code && code.length !== 8) || loadings.has("validateAndSign")}
            data-cy="button-signature-validate-and-sign"
            type="button"
            onClick={() => validateAndSign(code, location, phoneNumber)}
            className={`btn btn-primary btn-elevate ms-4 ${loadings.has("validateAndSign") && "spinner spinner-white spinner-right"}`}
          >
            {!loadings.has("validateAndSign") ? (
              <FormattedMessage id="SIGN.VALIDATE_AND_SIGN" />
            ) : (
              <span className="indicator-progress" style={{ display: "block" }}>
                <FormattedMessage id="GLOBAL.PLEASE_WAIT" /> <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
