import React, { FC } from "react";
import { Card, Spinner } from "react-bootstrap";

export const CardLoaderSpinner: FC = () => {
  return (
    <Card>
      <div className="card h-40">
        <div className="card-body d-flex justify-content-center text-center flex-column p-8">
          <div style={{ height: "40vh" }} className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" style={{ top: "30px", left: "20px" }} />
          </div>
        </div>
      </div>
    </Card>
  );
};
