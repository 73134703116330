import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../core";
import { DrawerComponent } from "../../assets/ts/components";

const Content: React.FC = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div id="kt_content_container" className={clsx(classes.contentContainer.join(" "))} style={{ padding: "0px 64px", margin: "16px auto", maxWidth: 1500 }}>
      {children}
    </div>
  );
};

export { Content };
