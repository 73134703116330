/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { setLanguage, useLang } from "../../../i18n/Metronici18n";
import { AVAILABLE_LANGUAGES } from "../../../i18n/languages";

const Languages: FC = () => {
  const lang = useLang();
  const currentLanguage = AVAILABLE_LANGUAGES.find((x) => x.lang === lang);
  const setUserAppLanguage = (lang: string) => {
    // Note: removed update of user language on backend, because one account is typically used for multiple people.
    // If updating backend -> bad ux, because people would change language for other people.
    // -> app language managed in local storage of user's browser.
    // -> this function will only update value in local storage
    setLanguage(lang);
  };

  return (
    <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start" data-kt-menu-flip="bottom">
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          <FormattedMessage id={"GLOBAL.LANGUAGE"} />
          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {currentLanguage?.name} <img className="w-15px h-15px rounded-1 ms-2" src={currentLanguage?.flag} alt="metronic" />
          </span>
        </span>
      </a>

      <div className="menu-sub menu-sub-dropdown w-175px py-4">
        {AVAILABLE_LANGUAGES.map((l) => (
          <div
            className="menu-item px-2"
            key={l.lang}
            onClick={() => {
              setUserAppLanguage(l.lang);
            }}
          >
            <a
              href="#"
              className={clsx("menu-link d-flex px-5", {
                active: l.lang === currentLanguage?.lang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="metronic" />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Languages };
