import { API } from "aws-amplify";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";

import { ISignature } from "../../types/signature";
import { getPdfPageCount } from "../components/utilsPdf";
import { DocumentType } from "../modules/signatures/components/DocumentHelper";

type Pdf = any;

export enum SignatureLoading {
  createPdf = "createPdf",
}

export interface PdfContextValues {
  pdfPageCount: number;
  pdfPreview: Pdf;
  createPdf: (signature: ISignature) => Promise<void>;
  updatePdf: (signature: ISignature) => Promise<void>;
  sealPreview: any;
  setSealImage: (sealPreview: any) => void;
  initialPdf: any;
  setPdf: (pdf: any) => void;
  initialPdfPrefilled: any;
  isPdfLoading: boolean;
  setIsPdfLoading: (val: boolean) => void;
  documentId: string;
  currentPage: number;
  setCurrentPage: (pageIndex: number) => void;
  setPdfPageCount: (num: number) => void;
  pageToRender: any;
  setPageToRender: (page: any) => void;
  loadLocalPdfTemplate: (templateId: string) => void;
  setIsExpanded: (val: boolean) => void;
  isExpanded: boolean;
}

interface SignaturesProviderProps {
  children?: ReactNode;
}

const pdf: any = "";

export const PdfContext = createContext<PdfContextValues>({
  pdfPageCount: 0,
  pdfPreview: pdf,
  createPdf: () => Promise.resolve(),
  updatePdf: () => Promise.resolve(),
  sealPreview: pdf,
  setSealImage: () => Promise.resolve(),
  initialPdf: undefined,
  setPdf: () => Promise.resolve(),
  initialPdfPrefilled: pdf,
  isPdfLoading: false,
  setIsPdfLoading: () => undefined,
  documentId: "",
  currentPage: 0,
  setCurrentPage: () => undefined,
  setPdfPageCount: () => undefined,
  pageToRender: pdf,
  setPageToRender: () => undefined,
  loadLocalPdfTemplate: (templateId: string) => undefined,
  setIsExpanded: (val: boolean) => undefined,
  isExpanded: false,
});

export const PdfProvider: FC<SignaturesProviderProps> = (props) => {
  const { children } = props;
  const [pdfPreview, setPdfPreview] = useState<any>(pdf);
  const [documentId, setDocumentId] = useState<any>("");
  const [pdfPageCount, setCurrentPdfPageCount] = useState<number>(0);
  const [sealPreview, setSealPreview] = useState<any>(undefined);
  const [initialPdf, setInitialPdf] = useState<any>(pdf);
  const [initialPdfPrefilled, setInitialPdfPrefilled] = useState<any>(pdf);
  const [isPdfLoading, setIsLoading] = useState<boolean>(false);
  const [pageToRender, setRenderPage] = useState<any>(undefined);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const [currentPage, setCurrentPdfPage] = useState<number>(0);

  const createPdf = async (signature: ISignature) => {
    API.post("API", "/signatures/pdf", { body: signature }).then((response) => {
      if (signature.documentType === DocumentType.Template) {
        const fileBase64 = "data:application/pdf;base64," + response?.pdfBase64;
        setDocumentId(response.documentId);
        setPdf(fileBase64);
        setInitialPdf(fileBase64);
        setPdfPageCount(response.pdfPageCount);
        setIsPdfLoading(false);
      } else {
        setDocumentId(response.fileId);
        fetch(response.url, {
          method: "PUT",
          body: signature.file,
          headers: {
            "Content-Type": "",
          },
        }).then();
      }
    });
  };

  const updatePdf = async (signature: ISignature) => {
    setIsPdfLoading(true);
    signature.documentId = documentId;
    signature.fileBase64 = initialPdf;
    signature.mode = "UPDATE";

    API.post("API", "/signatures/pdf", { body: signature })
      .then((response) => {
        const fileBase64 = response?.pdfBase64 ? "data:application/pdf;base64," + response?.pdfBase64 : undefined;

        if (DocumentType.Template) {
          setPdfPageCount(response.pdfPageCount);
          setPdf(fileBase64);
          setInitialPdfPrefilled(fileBase64);
        }
        setIsPdfLoading(false);
      })
      .catch(() => setIsPdfLoading(false));
  };

  const setSealImage = (image: any) => {
    setSealPreview(image);
  };

  const setPdf = (pdf: any) => {
    if (typeof pdf === "string") {
      setPdf(undefined);
    }

    if (pdf) {
      setPdfPreview(pdf);
      setCurrentPage(0);
      getPdfPageCount(pdf).then((pageCount) => setPdfPageCount(pageCount));
    }
  };

  const setIsPdfLoading = (val: boolean) => {
    setIsLoading(val);
  };

  const setCurrentPage = (pageIndex: number) => {
    setCurrentPdfPage(pageIndex);
  };

  const setPdfPageCount = (pageIndex: number) => {
    setCurrentPdfPageCount(pageIndex);
  };

  const setPageToRender = (pdf: any) => {
    setRenderPage(pdf);
  };

  const loadLocalPdfTemplate = async (templateId: string) => {
    try {
      const resourcePdf = await import(`../../assets/templates/pdf/${templateId}.pdf`);
      fetch(resourcePdf?.default).then((r) => {
        r?.body
          ?.getReader()
          ?.read()
          .then((res: any) => {
            const base64Pdf = "data:application/pdf;base64," + Buffer.from(res.value).toString("base64");
            setPdf(base64Pdf);
            setInitialPdf(base64Pdf);
          });
      });
    } catch (e) {
      console.error("error loading template", e);
    }
    setCurrentPage(0);
    setIsPdfLoading(false);
  };

  return (
    <PdfContext.Provider
      value={{
        pdfPageCount,
        pdfPreview,
        createPdf,
        updatePdf,
        sealPreview,
        setSealImage,
        initialPdf,
        setPdf,
        initialPdfPrefilled,
        isPdfLoading,
        setIsPdfLoading,
        documentId,
        currentPage,
        setCurrentPage,
        setPdfPageCount,
        pageToRender,
        setPageToRender,
        loadLocalPdfTemplate,
        isExpanded,
        setIsExpanded,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

PdfProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const PdfsConsumer = PdfContext.Consumer;
