import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useState } from "react";

type Loading = string;
type Loadings = Loading[];

interface LoadingContextValue {
  loadings: Loading[];
  loading: (key: Loading) => void;
  loadingFinished: (key: Loading) => void;
}

interface LoadingsProviderProps {
  children?: ReactNode;
}

export const LoadingsContext = createContext<LoadingContextValue>({
  loadings: [],
  loading: () => undefined,
  loadingFinished: () => undefined,
});

export const LoadingsProvider: FC<LoadingsProviderProps> = (props) => {
  const { children } = props;
  const [loadings, setLoadings] = useState<Loadings>([]);

  const loading = (key: Loading) => {
    setLoadings([...loadings, key]);
  };

  const loadingFinished = (key: Loading) => {
    const newLoadings = [...loadings];
    const index = newLoadings.indexOf(key);
    if (index > -1) {
      newLoadings.splice(index, 1);
    }
    setLoadings(newLoadings);
  };

  return (
    <LoadingsContext.Provider
      value={{
        loadings,
        loading,
        loadingFinished,
      }}
    >
      {children}
    </LoadingsContext.Provider>
  );
};

LoadingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LoadingsConsumer = LoadingsContext.Consumer;
