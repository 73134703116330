import { API } from "aws-amplify";
import PropTypes from "prop-types";
import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { createContext, useState } from "react";

import { ESignatoryStatus } from "../../types/signatory";
import { SNACKBAR_MESSAGE } from "../components/SnackbarsHandler";
import { useSnackbars } from "../hooks/use-snackbars";

type SignStepState = undefined | "OTP" | "INFO" | "SIGNED";

export interface SignContextValue {
  selectedSignature?: any;
  loadings: Set<string>;
  fetchSignatureById: (signatureId?: string, key?: string) => Promise<void>;
  sendCode: () => Promise<void>;
  validateAndSign: (code: string, location?: string, phoneNumber?: string) => Promise<void>;
  signStepState: SignStepState;
  setSignStepState: Dispatch<SetStateAction<SignStepState>>;
  nextCodeDelay?: Date;
  signError?: string;
  setSignError: Dispatch<SetStateAction<string | undefined>>;
}

interface SignProviderProps {
  children?: ReactNode;
}

export const SignContext = createContext<SignContextValue>({
  selectedSignature: undefined,
  loadings: new Set(),
  fetchSignatureById: (signatureId, key) => Promise.resolve(),
  sendCode: () => Promise.resolve(),
  validateAndSign: (code, location, phoneNumber) => Promise.resolve(),
  signStepState: undefined,
  setSignStepState: () => {},
  nextCodeDelay: undefined,
  setSignError: () => {},
});

export const SignProvider: FC<SignProviderProps> = (props) => {
  const { children } = props;
  const [loadings, setLoadings] = useState<Set<string>>(new Set());
  const [selectedSignature, setSelectedSignature] = useState<any>(undefined);
  const [signStepState, setSignStepState] = useState<SignStepState>();
  const [nextCodeDelay, setNextCodeDelay] = useState<Date>();
  const [accessKey, setAccessKey] = useState<string>("");
  const [signError, setSignError] = useState<string | undefined>();
  const { createSnackbar } = useSnackbars();

  const fetchSignatureById = async (signatureId?: string, accessKey?: string) => {
    setAccessKey(accessKey || "");
    setSelectedSignature(undefined);
    if (signatureId) {
      setLoadings(new Set(loadings).add("fetchSignature"));
      API.get("API", `/signatures/${signatureId}?key=${accessKey}`, {})
        .then((response) => {
          setSelectedSignature(response);
        })
        .catch(() => {
          createSnackbar(SNACKBAR_MESSAGE.DANGER.FETCH_SIGNATURE);
        })
        .finally(() => {
          const newLoadings = new Set(loadings);
          newLoadings.delete("fetchSignature");
          setLoadings(newLoadings);
        });
    }
  };

  const sendCode = async () => {
    setLoadings(new Set(loadings).add("sendCode"));
    API.get("API", `/signatures/${selectedSignature.signatureId}/otp?key=${accessKey}`, {})
      .then(() => {
        const nextOTPDelay = new Date();
        nextOTPDelay.setMinutes(nextOTPDelay.getMinutes() + 5);
        setNextCodeDelay(nextOTPDelay);
        createSnackbar(SNACKBAR_MESSAGE.INFO.SEND_CODE);
      })
      .catch((err) => {
        createSnackbar(SNACKBAR_MESSAGE.DANGER.SEND_CODE);
        let nextOTPDelay = Date.now();
        nextOTPDelay += err.response.data.retryAfter;
        setNextCodeDelay(new Date(nextOTPDelay));
      })
      .finally(() => {
        const newLoadings = new Set(loadings);
        newLoadings.delete("sendCode");
        setLoadings(newLoadings);
      });
  };

  const validateAndSign = async (code: string, location?: string, phoneNumber?: string) => {
    setLoadings(new Set(loadings).add("validateAndSign"));
    API.post("API", `/signatures/${selectedSignature.signatureId}/otp?key=${accessKey}`, {
      body: { code, location, phoneNumber },
    })
      .then((response) => {
        setSignStepState(ESignatoryStatus.SIGNED);
        setSelectedSignature(response);
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.VALIDATE_SIGN);
        setSignError(undefined);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setSignError("invalidCode");
        }
        createSnackbar(SNACKBAR_MESSAGE.DANGER.VALIDATE_SIGN);
      })
      .finally(() => {
        const newLoadings = new Set(loadings);
        newLoadings.delete("validateAndSign");
        setLoadings(newLoadings);
      });
  };

  return (
    <SignContext.Provider
      value={{
        selectedSignature,
        loadings,
        fetchSignatureById,
        sendCode,
        validateAndSign,
        signStepState,
        setSignStepState,
        nextCodeDelay,
        signError,
        setSignError,
      }}
    >
      {children}
    </SignContext.Provider>
  );
};

SignProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SignConsumer = SignContext.Consumer;
