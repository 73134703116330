import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";

import { SNACKBAR_MESSAGE } from "../../../../components/SnackbarsHandler";
import { AuthLoading } from "../../../../contexts/auth-context";
import { useAuth } from "../../../../hooks/use-auth";
import { useLoadings } from "../../../../hooks/use-loadings";
import { useSnackbars } from "../../../../hooks/use-snackbars";
import { PASSWORD } from "../../../../utils/regExUtils";

export const ChangePassword = () => {
  const auth = useAuth();
  const intl = useIntl();
  const { loadings } = useLoadings();
  const { createSnackbar } = useSnackbars();

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required().label("USERS.CURRENT_PASSWORD"),
    newPassword: Yup.string()
      .min(8)
      .max(50)
      .required()
      .matches(PASSWORD, intl.formatMessage({ id: "USERS.PASSWORD_REGEX" }))
      .label("USERS.NEW_PASSWORD"),
    repeatNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newPassword"), null], intl.formatMessage({ id: "GLOBAL.MATCH_PASSWORD" }))
      .label("AUTH.INPUT.CONFIRM_PASSWORD"),
  });

  const submitChangePassword = (values: any, { setStatus, setSubmitting, resetForm }: FormikValues) => {
    auth
      .changePassword(auth.user?.cognitoUser, values)
      .then(() => {
        createSnackbar(SNACKBAR_MESSAGE.SUCCESS.PASSWORD_UPDATE);
        resetForm();
      })
      .catch((err) => {
        console.error("Change the password - Error:", err);
        setSubmitting(false);
        setStatus(err.code);
      });
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate" aria-expanded="true" aria-controls="kt_account_deactivate">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">
            <FormattedMessage id="USERS.CHANGE_PASSWORD" />
          </h3>
        </div>
      </div>
      <div id="kt_account_deactivate" className="collapse show">
        <Formik
          enableReinitialize={true}
          initialValues={{
            currentPassword: "",
            newPassword: "",
            repeatNewPassword: "",
          }}
          validationSchema={changePasswordSchema}
          onSubmit={submitChangePassword}
        >
          {({ values, status }) => (
            <Form noValidate>
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <FormattedMessage id="USERS.CURRENT_PASSWORD" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field type="password" name="currentPassword" className="form-control form-control-lg form-control-solid" />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="currentPassword" />
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <FormattedMessage id="USERS.NEW_PASSWORD" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field type="password" name="newPassword" className="form-control form-control-lg form-control-solid" />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="newPassword" />
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    <FormattedMessage id="USERS.REPEAT_NEW_PASSWORD" />
                  </label>

                  <div className="col-lg-8 fv-row">
                    <Field type="password" name="repeatNewPassword" className="form-control form-control-lg form-control-solid" />
                    <div className="text-danger mt-2">
                      <ErrorMessage name="repeatNewPassword" />
                    </div>
                  </div>
                </div>
                {status && (
                  <div className="row mb-6 pt-8 mb-0">
                    <div className="text-center pb-8">
                      <span className="text-danger fw-bold fs-6 mr-auto">
                        <FormattedMessage id="USERS.WRONG_PASSWORD" />
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button id="kt_account_deactivate_account_submit" type="submit" className="btn fw-bold btn-primary">
                  {!loadings.includes(AuthLoading.changePassword) ? (
                    <FormattedMessage id="GLOBAL.SAVE" />
                  ) : (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      <FormattedMessage id="GLOBAL.PLEASE_WAIT" />
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
