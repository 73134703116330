// Redux
// https://github.com/rt2zz/redux-persist
import * as Sentry from "@sentry/react";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AuthProvider } from "./app/contexts/auth-context";
import { AppRoutes } from "./app/routing/AppRoutes";
import * as _redux from "./setup";
import setupAmplify from "./setup/amplify/SetupAmplify";
import store, { persistor } from "./setup/redux/Store";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);
setupAmplify();

Chart.register(...registerables);

const hostname = window?.location?.hostname || "";

if (hostname.includes("dev") || hostname.includes("lux-sign")) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: hostname.includes("dev") ? 1.0 : 0.7, // For dev 100% and for prod 70% of traffic
    // Session Replay
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <AuthProvider>
    <MetronicI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <AppRoutes />
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
  </AuthProvider>,
  document.getElementById("root"),
);
