import Amplify, { Analytics, Auth } from "aws-amplify";

console.log(
  `   _                 _            _        _  _
  | |__    ___    __| |    ___   | |_     | || |  __ __    ___
  | / /   / _ \\  / _\` |   / -_)  | ' \\     \\_, |  \\ V /   / -_)
  |_\\_\\   \\___/  \\__,_|   \\___|  |_||_|   _|__/   _\\_/_   \\___|
_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_| """"|_|"""""|_|"""""|
"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'
`,
);
console.log("https://www.kodehyve.com/");

if (!window.location.hostname.includes("localhost")) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

export default function setupAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: "API",
          endpoint: process.env.REACT_APP_API_URL,
          custom_header: async () => {
            try {
              return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
              };
            } catch (e) {
              return {};
            }
          },
        },
      ],
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: process.env.REACT_APP_PINPOINT_APP_ID,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        bufferSize: 1000,
        flushInterval: 5000, // 5s
        flushSize: 100,
        resendLimit: 5,
      },
    },
  });

  Analytics.autoTrack("pageView", {
    enable: true,
    eventName: "pageView",
    type: "SPA",
    provider: "AWSPinpoint",
    getUrl: () => {
      // the default function
      return window.location.origin + window.location.pathname;
    },
  });

  Analytics.autoTrack("event", {
    enable: true,
    events: ["click"],
    selectorPrefix: "data-aws-analytics-",
    provider: "AWSPinpoint",
  });
}
