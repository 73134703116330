import * as Sentry from "@sentry/react";
import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { LayoutInit } from "../_metronic/layout/LayoutInit";

import { SnackbarsDialog } from "./components/SnackbarsDialog";
import { LoadingsProvider } from "./contexts/loadings-context";
import { PdfProvider } from "./contexts/pdf-context";
import { SignaturesProvider } from "./contexts/signatures-context";
import { SnackbarsProvider } from "./contexts/snackbars-context";
import { UsersProvider } from "./contexts/users-context";
import AuthInit from "./modules/auth/redux/AuthInit";
import { Intercom } from "./utils/intercomUtils";

const App = () => {
  useEffect(() => {
    Intercom.load();
  }, []);

  const location = useLocation();
  useEffect(() => {
    Intercom.update();
  }, [location]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LoadingsProvider>
          <SnackbarsProvider>
            <LayoutProvider>
              <UsersProvider>
                <PdfProvider>
                  <SignaturesProvider>
                    <AuthInit>
                      <Outlet />
                      <SnackbarsDialog />
                      <LayoutInit />
                    </AuthInit>
                  </SignaturesProvider>
                </PdfProvider>
              </UsersProvider>
            </LayoutProvider>
          </SnackbarsProvider>
        </LoadingsProvider>
      </I18nProvider>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
