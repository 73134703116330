import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { toAbsoluteUrl } from "../../../_metronic/helpers";

import { AcceptGdpr } from "./components/AcceptGdpr";
import { NewPasswordRequired } from "./components/NewPasswordRequired";

// noinspection DuplicatedCode
const AuthChallengesLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      // Commented out, just in case there will be a background image at some point in the future*
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/sketchy-1/14.png")})`,
      // }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="/" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo.png")} className="h-100px" />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-sm-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="/" className="text-muted text-hover-primary px-2">
            About
          </a>

          <a href="/" className="text-muted text-hover-primary px-2">
            Contact
          </a>

          <a href="/" className="text-muted text-hover-primary px-2">
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

const AuthChallengesPage = () => (
  <Routes>
    <Route element={<AuthChallengesLayout />}>
      <Route path="new_password_required" element={<NewPasswordRequired />} />
      <Route path="gdpr" element={<AcceptGdpr />} />
    </Route>
  </Routes>
);

export { AuthChallengesPage };
