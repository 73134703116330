import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAuth } from "../../../hooks/use-auth";
import { PASSWORD } from "../../../utils/regExUtils";

export function ForgotPassword() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorKey, setErrorKey] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>("");

  const submitEmail = (values: any, { setSubmitting }: FormikValues) => {
    setLoading(true);
    setErrorKey(undefined);
    auth
      .forgotPassword(values.email)
      .then(() => {
        setEmail(values.email);
        setErrorKey("AUTH.FORGOT.EMAIL_SENT");
      })
      .catch(() => {
        setErrorKey("AUTH.EMAIL");
        setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitChangePassword = (values: any, { setSubmitting }: FormikValues) => {
    const { code, newPassword, repeatNewPassword } = values;
    setLoading(true);
    setErrorKey(undefined);
    if (newPassword !== repeatNewPassword) {
      setErrorKey("GLOBAL.MATCH_PASSWORD");
      setLoading(false);
      return;
    }
    auth
      .forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        setLoading(false);
        navigate("/login");
      })
      .catch(() => {
        setErrorKey("AUTH.INVALID_CODE");
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          <FormattedMessage id="AUTH.FORGOT_PASSWORD" />
        </h1>
        <div className="text-gray-400 fw-bold fs-4">
          <FormattedMessage id="AUTH.FORGOT.DESC" />
        </div>
      </div>
      {errorKey !== undefined && (
        <>
          {errorKey !== "AUTH.FORGOT.EMAIL_SENT" ? (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                <FormattedMessage id={errorKey} />
              </div>
            </div>
          ) : (
            <div className="mb-10 bg-light-info p-8 rounded">
              <div className="text-info">
                <FormattedMessage id="AUTH.FORGOT.EMAIL_SENT" />
              </div>
            </div>
          )}
        </>
      )}
      {email ? <SubmitPassword submit={submitChangePassword} loading={loading} /> : <SendEmail submit={submitEmail} loading={loading} />}
    </>
  );
}

interface Props {
  submit: any;
  loading: boolean;
}

export const SendEmail: FC<Props> = ({ submit, loading }) => {
  const schema = Yup.object().shape({
    email: Yup.string().min(3).max(50).email().required().label("GLOBAL.EMAIL"),
  });
  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      initialValues={{
        email: "",
      }}
      onSubmit={submit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_password_reset_form">
          <div className="fv-row mb-10">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              <FormattedMessage id="GLOBAL.EMAIL" />
            </label>
            <Field name="email" className={clsx("form-control form-control-lg form-control-solid")} />
            <div className="text-danger mt-2">
              <ErrorMessage name="email" />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-primary fw-bolder me-4" disabled={!values.email}>
              {!loading ? (
                <span className="indicator-label">
                  <FormattedMessage id="GLOBAL.SUBMIT" />
                </span>
              ) : (
                <span className="indicator-progress d-block">
                  <FormattedMessage id="GLOBAL.PLEASE_WAIT" />
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </button>
            <Link to="/auth/login">
              <button type="button" id="kt_login_password_reset_form_cancel_button" className="btn btn-lg btn-light-primary fw-bolder" disabled={isSubmitting || !isValid}>
                <FormattedMessage id="GLOBAL.CANCEL" />
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const SubmitPassword: FC<Props> = ({ submit, loading }) => {
  const intl = useIntl();
  const schema = Yup.object().shape({
    code: Yup.string().max(6).required().label("SIGN.CODE"),
    newPassword: Yup.string()
      .min(8)
      .max(50)
      .matches(PASSWORD, intl.formatMessage({ id: "USERS.PASSWORD_REGEX" }))
      .required()
      .label("USERS.NEW_PASSWORD"),
    repeatNewPassword: Yup.string().required().label("AUTH.INPUT.CONFIRM_PASSWORD"),
  });
  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      initialValues={{
        code: "",
        newPassword: "",
        repeatNewPassword: "",
      }}
      onSubmit={submit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_password_reset_form">
          <>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                <FormattedMessage id="SIGN.CODE" />
              </label>
              <Field name="code" className={clsx("form-control form-control-lg form-control-solid")} />
              <div className="text-danger mt-2">
                <ErrorMessage name="code" />
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                <FormattedMessage id="USERS.NEW_PASSWORD" />
              </label>
              <Field type="password" name="newPassword" className={clsx("form-control form-control-lg form-control-solid")} />
              <div className="text-danger mt-2">
                <ErrorMessage name="newPassword" />
              </div>
            </div>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                <FormattedMessage id="USERS.REPEAT_NEW_PASSWORD" />
              </label>
              <Field type="password" name="repeatNewPassword" className={clsx("form-control form-control-lg form-control-solid")} />
              <div className="text-danger mt-2">
                <ErrorMessage name="repeatNewPassword" />
              </div>
            </div>
          </>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button type="submit" id="kt_password_reset_submit" className="btn btn-lg btn-primary fw-bolder me-4" disabled={!(values.code && values.newPassword && values.repeatNewPassword)}>
              {!loading ? (
                <span className="indicator-label">
                  <FormattedMessage id="GLOBAL.SUBMIT" />
                </span>
              ) : (
                <span className="indicator-progress d-block">
                  <FormattedMessage id="GLOBAL.PLEASE_WAIT" />
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </button>
            <Link to="/auth/login">
              <button type="button" id="kt_login_password_reset_form_cancel_button" className="btn btn-lg btn-light-primary fw-bolder" disabled={isSubmitting || !isValid}>
                <FormattedMessage id="GLOBAL.CANCEL" />
              </button>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
